.products-tabs-wrapper {
    padding-top: 70px;
    .tabs-list-items {
        padding-bottom: 42px;
        li {
            display: inline-block;
            padding-left: 0;
            vertical-align: middle;
            margin-right: 16px;
            &:last-of-type {
                margin-right: 0;
            }
            a {
                background-color: $general_green;
                color: $main_white;
                display: inline-block;
                font-weight: $semibold;
                min-width: 166px;
                padding: 11px 10px;
                text-align: center;
                text-transform: uppercase;
                &:hover {
                    &.inactive {
                        background-color: $general_green;
                    }    
                }
                &.inactive {
                    background-color: $green_light_dot;
                }
            }
            &:after {
                display: none;
            }
        }
    }
}
