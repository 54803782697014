.site-header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9;
    .logged-in & {
        top: 32px;
    }
    a {
        font-size: $font-size-base - 1;
        @include max-mq ($mac - 10) {
            font-size: $font-size-base - 2;
        }
    }
    .login-header-menu {
        margin-left: 27px;
        @include max-mq ($mac - 10) {
            margin-left: 15px;
        }
    }
    .phone-menu-header-wrapper {
        @include max-mq ($ls - 30) {
            position: absolute;
            right: 120px;
            top: 17px;
        }
        @include max-mq ($md_l) {
            top: 30px;
        }
        @include max-mq ($sm - 1) {
            top: 42px;
        }
        @include max-mq ($mb_l) {
            right: 93px;
        }
        .menu-side-header-menu-container {
            @include max-mq ($sm - 1) {
                display: none;
            }
        }
        li {
            &.login-icon {
                background-image: url('../images/lock-icon.png');
                background-position: center left;
                background-repeat: no-repeat;
                padding-left: 2px;
            }
            &.green-button {
                background-color: rgba($color: $main_white, $alpha: 0);
                transition: all .5s;
                position: relative;
                &:hover {
                    background-color: rgba($color: $main_white, $alpha: 1);
                    a {
                        color: $general_green;
                    }
                }
                &:before {
                    background-image: -ms-linear-gradient(left, #3BC5BD 0%, #129D96 100%);
                    background-image: -moz-linear-gradient(left, #3BC5BD 0%, #129D96 100%);
                    background-image: -o-linear-gradient(left, #3BC5BD 0%, #129D96 100%);
                    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #3BC5BD), color-stop(100, #129D96));
                    background-image: -webkit-linear-gradient(left, #3BC5BD 0%, #129D96 100%);
                    background-image: linear-gradient(to right, #3BC5BD 0%, #129D96 100%);
                    bottom: 0;
                    content: '';
                    height: 100%;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                    z-index: -1;
                }
                a {
                    color: $main_white;
                    text-transform: uppercase;
                }
            }
            a {
                padding: 16px 20px;
                @include max-mq ($mac - 10) {
                    padding: 13px 14px;
                }
            }
        }
    }
    
    
}
.contact-login-details,
.main-logo-header-main-menu-wrapper {
    display: flex;
    align-items: center;
}
.main-logo-header-main-menu-wrapper {
    flex: 0 0 68%;
    max-width: 68%;
    @include max-mq ($mac - 30) {
        flex: 0 0 70%;
        max-width: 70%;
    }
    @include max-mq ($ls - 30) {
        flex: 0 0 100%;
        justify-content: space-between;
        max-width: 100%;
    }
}
.logo-menu-wrapper {
    padding: 24px 53px;
    @include max-mq ($mac + 50) {
        padding: 22px 40px 10px 30px;
    }
    @include max-mq ($md_l) {
        padding-top: 35px;
    }
    @include max-mq ($mb_l) {
        padding-right: 24px;
    }
}
.site-branding-main-logo {
    max-width: 277px;
    padding-left: 17px;
    width: 100%;
    @include max-mq ($mac + 30) {
        max-width: 250px;
        padding-left: 0;
    }
    @include max-mq ($ls) {
        max-width: 230px;
    }
    @include max-mq ($mb_mb) {
        max-width: 210px;
    }
    @include max-mq ($xs) {
        max-width: 205px;
    }
}
#header-phone {
    @include max-mq ($md_l) {
        position: relative;
        top: 1px;
    }
    @include max-mq ($mb) {
        display: none;
    }
    a {
        color: $main_white;
        &:hover {
            color: $menu_color;
        }
    }
}
.phone-icon-wrap {
    padding-right: 11px;
    position: relative;
    top: 1px;
    @include max-mq ($mac - 10) {
        padding-right: 1px;
    }
}
.main-navigation {
    padding-left: 49px;
    margin-top: -7px;
    @include max-mq ($lt_l - 10) {
        padding-left: 25px;
    }
    @include max-mq ($ls) {
        padding-left: 15px;
    }
    @include max-mq ($mb_l) {
        padding-left: 0;
    }
}
