div {
    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="search"],
    input[type="number"],
    input[type="tel"],
    input[type="range"],
    input[type="date"],
    input[type="month"],
    input[type="week"],
    input[type="time"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="color"],
    textarea {
      background-color: $main_white;
      border: 1px solid $grey_form;
      border-radius: 5px;
      color: $placeholder_color;
      font-family: $primary_font;
      font-size: $font-size-base - 1;
      font-weight: $regular;
      line-height: 1em;
      padding: 5px 17px;
      vertical-align: middle;
      width: 100%;
      .page-template-contact-us-page & {
        background-color: $grey_background;
        padding-left: 26px;
        padding-right: 26px;
      }
    }
    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="search"],
    input[type="number"],
    input[type="tel"],
    input[type="range"],
    input[type="date"],
    input[type="month"],
    input[type="week"],
    input[type="time"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="color"]
    select {
        height: 39px;
        .page-template-contact-us-page & {
            height: 63px;
        }
    }
    textarea {
        padding-left: 1.1em;
        padding-top: 0.7em;
        resize: none;
        width: 100%;
        height: 81px;
        .page-template-contact-us-page & {
            height: 117px;
        }
    }
  }
  
  form input.frm_verify {
      display: none !important;
  }
  
  .frm_screen_reader {
      border: 0;
      clip: rect(1px, 1px, 1px, 1px);
      -webkit-clip-path: inset(50%);
      clip-path: inset(50%);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      word-wrap: normal !important;
  }
  
  
  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    color: $main_dark;
    border: 1px solid;
    border-radius: 0;
    font-family: $secondary_font;
    font-size: $font-size-base;
    line-height: 1.15em;
    padding: 10px;
    vertical-align: middle;
    
  }
  
  .frm_fields_container {
      &:after {
          content: '';
          display: block;
          clear: both;
      }
  }
  
  .frm_submit,
  .frm_full {
      float: left;
      width: 100%;
  }
  .frm_half {
      float: left;
      margin-left: 2.7%;
      width: 48.5%;
      @include max-mq($mb_p) {
        margin-left: 0;
        width: 100%;
      }
      &.frm_first {
          margin-left: 0;
          clear: left;
      }
  }
  
  .frm_submit {
      text-align: left;
      padding-top: 12px;
      .page-template-contact-us-page & {
        text-align: center;
        padding-top: 30px;
      }
      .frm_button_submit {
          &.frm_final_submit {
            //   box-shadow: 0px 0px 14px 0px rgba($color: $main_dark, $alpha: 0.2);
              padding: 15px 60px;
          }
      }
  }
  
  .frm_required {
      display: none; 
  }
  
  .frm_screen_reader {
      opacity: 0;
  }
  
  .frm_form_fields  {
      fieldset {
          border: none;
          margin: 0;
          padding: 0;
      }
  }
  
  .frm_form_field {
      &.form-field {
          margin-bottom: 11px;
      }
  }
  .frm_forms {
    .frm_error {
        font-size: $font-size-small - 2;
        color: red;
    }
  }
  
  /*---- Placeholder ----*/
  ::-webkit-input-placeholder {
    opacity: 1;
    color: $placeholder_color !important;
  }
  \:-moz-placeholder {
    opacity: 1;
    color: $placeholder_color !important;
  }
  ::-moz-placeholder {
    opacity: 1;
    color: $placeholder_color !important;
  }
  \:-ms-input-placeholder {
    opacity: 1;
    color: $placeholder_color !important;
  }
  *::-webkit-input-placeholder {
    opacity: 1;
    color: $placeholder_color !important;
  }
  *:-moz-placeholder {
    opacity: 1;
    color: $placeholder_color !important;
  }
  *:-ms-input-placeholder {
    opacity: 1;
    color: $main_white !important;
  }
  