.hero-image-style-wrapper {
    align-items: center;
    display: flex;
    min-height: 700px;
    padding-bottom: 154px;
    padding-top: 220px;
    position: relative;
    @include max-mq ($lg) {
        padding-left: 15px;
        padding-right: 15px;
    }
    @include max-mq ($md) {
        padding-bottom: 114px;
        padding-top: 150px;
    }
    @include max-mq ($mb) {
        padding-bottom: 90px;
        padding-top: 120px;
    }
    &:before {
        background-color: rgba($color: $main_dark, $alpha: 0.46);
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 0;
    }
    &:after {
        box-shadow: 0px 0px 230px 50px rgba($color: $main_dark, $alpha: 0.8);
        content: '';
        display: block;
        height: 110px;
        left: 0;
        position: absolute;
        right: 0;
        top: -110px;
        width: 100%;
        z-index: 0;
    }
    > div {
        position: relative;
        z-index: 1;
    }
}
.hero-row {
    flex-direction: column;
}
.hero-section-inner {
    .title-text-description-inner {
        max-width: 530px;
    }
    .main-section-heading {
        color: $main_white;
        font-size: $large_hero_font;
        font-weight: $bold;
        letter-spacing: 1px;
        line-height: 0.85;
        margin-bottom: 0.15em;
        margin-top: 0.82em;
        text-transform: uppercase;
        @include max-mq ($lg) {
            font-size: $large_hero_font - 8;
        }
        @include max-mq ($mb) {
            font-size: $large_hero_font - 16;
        }
        @include max-mq ($mb_mb) {
            font-size: $large_hero_font - 22;
        }
        @include max-mq ($xs) {
            font-size: $large_hero_font - 26;
        }
    }
    .hero-text-description {
        color: $main_white;
        font-weight: $semibold;
        a,
        p {
            color: $main_white;
        }
        a {
            &:hover {
                color: $general_green;
            }
        }
    }
    .select-form-wrapper {
        margin-top: 34px;
        max-width: 76%;
        @include max-mq ($lg) {
            max-width: 877px;
            width: 100%;
        }
        @include max-mq ($md_l) {
            max-width: 672px;
        }
        @include max-mq ($sm - 68) {
            max-width: 570px;
        }
        @include max-mq ($mb_l) {
            max-width: 448px;
        }
        @include max-mq ($mb + 13) {
            max-width: 100%;
        }
        // @include max-mq ($ip - 14) {
        //     max-width: 360px;
        // }
        // @include max-mq ($mb_mb) {
        //     max-width: 329px;
        // }
        // @include max-mq ($xs) {
        //     margin-top: 20px;
        // }
    }
}
.select-form-wrapper {
    .select-form-heading {
        color: $main_white;
        font-size: $font-size-small;
        font-weight: $semibold;
        p {
            margin-bottom: 0.6em;
        }
    }
    .select-form-inner {
        background-color: rgba($color: $main_dark, $alpha: 0.35);
        padding: 22px 25px 22px 62px;
        @include max-mq ($lg) {
            padding: 22px 25px 22px 48px;
        }
        @include max-mq ($sm - 68) {
            padding: 18px 22px 22px 22px;
        }
        @include max-mq ($mb) {
            padding: 16px 15px 22px;
        }
        @include max-mq ($xs) {
            padding: 15px 10px 20px;
        }
    }
    .select-button-submit {
        margin-left: 8px;
        padding: 21px 24px;
        @include max-mq ($sm - 68) {
            padding: 13px 13px;
        }
        @include max-mq ($mb_l) {
            margin-left: 0;
            margin-top: 20px;
        }
        @include max-mq ($mb + 13) {
            // display: block;
            margin-left: 0;
            margin-right: 0;
            margin-top: 20px;
            max-width: 140px;
            padding: 13px 15px 14px;
            text-align: center;
            width: 100%;
        }
        @include max-mq ($ip - 14) {
            padding: 13px 6px 14px;
        }
        @include max-mq ($xs) {
            font-size: $font-size-base - 3;
        }
    }
}

.colors-line-wrap {
    display: block;
    margin-bottom: 10px;
    &.colors-line-wrap-center {
        text-align: center;
    }
    > span {
        display: inline-block;
        height: 10px;
        width: 10px;
    }
    .blue {
        background-color: $blue_dot;
    }
    .purple {
        background-color: $purple_dot;
    }
    .light_green {
        background-color: $green_light_dot;
    }
    .green {
        background-color: $green_dot;
    }
    .light_orange {
        background-color: $orange_light_dot;
    }
    .orange {
        background-color: $orange_dot;
    }
}
.select-office-wrapper {
    display: block;
    width: 100%;
}