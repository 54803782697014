.featured-post-item-wrapper {
    padding: 5px 0 55px;
    @include max-mq ($lg) {
        padding: 5px 15px 45px;
    }
}
.featured-post-item-inner {
    width: 100%;
}

.featured-posts-content-inner {
    display: flex;
    @include max-mq ($sm) {
        display: block;
    }
    .featured-post-title {
        margin-bottom: 0.35em;
        margin-top: 0.2em;
    }
    .featured-post-excerpt {
        &.post-page-excerpt {
            padding-top: 16px;
            p {
                margin-bottom: 1.65em;
                @include max-mq ($ipad) {
                    margin-bottom: 1em;
                }
            }
        }
    }
    .featured-posts-thumnail-wrapper {
        flex: 0 0 35.4%;
        max-width: 35.4%;
        @include max-mq ($sm) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
        &.image-wrapped-decoration-style {
            &:before {
                height: 170px;
                width: 170px;
            }
        }
    }
    .featured-blog-title-excerpt-wrapper {
        flex: 0 0 64.6%;
        max-width: 64.6%;
        padding-left: 40px;
        @include max-mq ($ipad) {
            padding-left: 25px;
        }
        @include max-mq ($sm) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            padding-left: 0;
            padding-top: 20px;
        }
        .read-more-wrap {
            padding-top: 15px;
        }
    }
}
.post-author {
    a {
        &:hover {
            color: $general_green;
        }
    }
}