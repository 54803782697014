.hero-section-inner-page-wrapper {
    .hero-image-style-wrapper {
        align-items: flex-end;
        background-size: cover;
        min-height: 740px;
        padding-bottom: 106px;
        @include max-mq ($ipad) {
            min-height: 690px;
        }
        @include max-mq ($mb_p) {
            min-height: 600px;
        }
    }
    .colors-line-wrap {
        padding-top: 27px;
    }
    .hero-text-description {
        padding-top: 10px;
    }
}