.latest-posts-section-wrapper {
    padding: 111px 0 118px;
    @include max-mq ($sm - 1) {
        padding: 90px 0 40px;
        max-width: 580px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    @include max-mq ($mb) {
        padding: 75px 0 30px;
    }
    .main-section-heading {
        text-transform: none;
        font-size: $font-size-h2;
        margin-bottom: 0.3em;
        @include max-mq ($sm) {
            font-size: $font-size-h2 - 5;
            padding-left: 15px;
            padding-right: 15px;
        }
        @include max-mq ($mb) {
            font-size: $font-size-h2 - 9;
        }
    }
    .container-latest-posts {
        max-width: 1168px;
    }
}
.latest-post-items-wrapper {
    @include max-mq ($lg) {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.featured-posts-thumnail-wrapper {
    a {
        &:hover {
            .image-wrapped-decoration-style:before {
                background-color: $button_orange;
            }
        }
    }
}

.image-wrapped-decoration-style {
    position: relative;
    img {
        display: block;
        width: 100%;
        position: relative;
        z-index: 1;
    }
    &:before {
        background-color: $green_light_dot;
        content: '';
        display: block;
        height: 140px;
        left: -7px;
        position: absolute;
        top: -7px;
        transition: all .5s;
        width: 140px;
        z-index: 0;
    }
}
.latest-posts-items {
    .latest-posts-slider {
        display: flex;
        justify-content: space-between;
        padding-top: 26px;
        @include max-mq ($sm - 1) {
            display: block;
        }
    }
}
.post-page-common-elements {
    .date-author-excerpt-wrapper {
        padding-bottom: 21px;
    }
    a {
        &:hover {
            .post-page-post-title {
                color: $general_green;
            }
        }
        
    }
    .post-page-post-title {
        color: $placeholder_color;
        display: inline-block;
        font-size: $font-size-h3;
        font-weight: $bold;
        letter-spacing: -0.4px;
        line-height: 1.35;
        margin-bottom: 0.6em;
        transition: all .5s;
        @include max-mq ($md) {
            font-size: $font-size-h3 - 3;
        }
    }
    .post-page-author-date {
        span {
            font-size: $font-size-base - 1;
            display: inline-block;
            vertical-align: middle;
        }
    }
    .by-author,
    .latest-post-date-value {
        color: $author_color;
    }
    .latest-post-date-value {
        padding-right: 12px;
    }
    .by-author {
        // border-left: 1px solid $author_color;
        position: relative;
        padding-left: 17px;
        &:before {
            content: '';
            position: absolute;
            height: 70%;
            bottom: 0;
            left: 0;
            width: 1px;
            background-color: $author_color;
        }
    }
    .post-author {
        color: $blue_dot;
        font-weight: $semibold;
    }
    .post-page-excerpt {
        color: $paragraph_color;
        padding-top: 22px;
        @include min-mq ($fhd - 100) {
            padding-right: 20px;
        }
    }
    .read-more-wrap {
        .button {
            padding-left: 38px;
            padding-right: 38px;
        }
    }
}
article.latest-post {
    flex: 0 0 30%;
    max-width: 30%;
    @include max-mq ($sm - 1) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-bottom: 45px;
        padding-top: 15px;
        width: 100%;
    }
    
}