.blog-page-latest-posts-content-wrapper {
    padding-bottom: 91px;
    padding-top: 13px;
    @include max-mq ($lg) {
        padding: 10px 15px 70px;
    }
    @include max-mq ($mb) {
        padding-bottom: 50px;
    }
    .latest-post {
        padding-bottom: 60px;
        padding-top: 60px;
        @include max-mq ($mb) {
            padding-bottom: 25px;
            padding-top: 25px;
        }
    }
    .blog-navigation {
        margin-top: -27px;
        @include max-mq ($mb) {
            margin-top: 0;
        }
    }
}
.blog-page-posts-row {
    flex-wrap: wrap;
    justify-content: space-between;
}
.blog-navigation {
    padding: 0 24px 25px 0;
    text-align: right;
    width: 100%;
    .page-numbers {
        background-color: $general_green;
        bottom: 10px;
        color: $main_white;
        cursor: pointer;
        display: none;
        font-size: $font-size-small;
        height: 25px;
        line-height: 25px;
        text-align: center;
        top: auto;
        transition: all .5s;
        width: 25px;
        &:hover {
            opacity: 0.5;
        }
        &.prev,
        &.next {
            display: inline-block;
            vertical-align: middle;
        }
        &.prev {
            margin-right: 10px;
        }
        &.next {
            text-indent: 1px;
        }
    }
}