.button {
    box-shadow: none;
    font-size: $font-size-base - 1;
    font-weight: $semibold;
    padding: 13px 32px;
    text-transform: uppercase;
    transition: all .5s;
    @include max-mq ($mb) {
        font-size: $font-size-base - 2;
        padding: 12px 22px;
    }
    &.button-orange {
        background-color: $button_orange;
        border: 1px solid $button_orange;
        color: $main_white;
        &:hover {
            background-color: $main_white;
            color: $button_orange;
        }
    }
    &.button-green {
        background-color: $general_green;
        border: 1px solid $general_green;
        color: $main_white;
        &:hover {
            background-color: $main_white;
            color: $general_green;
        }
    }
}