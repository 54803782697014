%gradient-top-bottom-right {
    background-image: -ms-linear-gradient(top left, #EF8408 0%, #EE4C22 100%);
    background-image: -moz-linear-gradient(top left, #EF8408 0%, #EE4C22 100%);
    background-image: -o-linear-gradient(top left, #EF8408 0%, #EE4C22 100%);
    background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0, #EF8408), color-stop(100, #EE4C22));
    background-image: -webkit-linear-gradient(top left, #EF8408 0%, #EE4C22 100%);
    background-image: linear-gradient(to bottom right, #EF8408 0%, #EE4C22 100%);
}
%box-shadow {
    box-shadow: 0px 0px 14px 0px rgba($color: $main_dark, $alpha: 0.12);
}
%box-shadow-light {
    box-shadow: 0px 10px 14px 0px rgba($color: $main_dark, $alpha: 0.05);
}
%box-shadow-light-hover {
    box-shadow: 0px 10px 14px 0px rgba($color: $main_dark, $alpha: 0.12);
}
%margin-auto-positioning {
    margin-left: auto;
    margin-right: auto;
}
%center-auto-positioning {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}
%flex-center {
    align-items: center;
    display: flex;
}
%left-calc {
    padding-left: calc(50vw - ( #{$container_width} / 2 ) - 15px);
    width: 100%;
    @include max-mq ($ls - 30) {
        padding-left: 15px;
    }
}