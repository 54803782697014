.products-items-wrapper {
    padding-top: 92px;
    @include max-mq ($mb) {
        padding-top: 62px;
    }
}
.main-section-subheading {
    color: $subheading_blue;
    font-weight: $bold;
    text-transform: uppercase;
    letter-spacing: 7px;
}
.main-section-heading {
    font-size: $font-size-h1;
    letter-spacing: 0.5px;
    margin-bottom: 0.2em;
    margin-top: 0;
    text-transform: uppercase;
    @include max-mq ($sm) {
        font-size: $font-size-h1 - 10;
    }
    @include max-mq ($mb) {
        font-size: $font-size-h1 - 15;
    }
}
.bag-img-grey-tape {
    background-image: url('../images/worcspace-background.svg');
    background-position: 0 center;
    background-repeat: no-repeat;
    background-size: cover;
    @include max-mq ($md_l) {
        background-position: top;
        background-size: 300%;
    }
}

.products-items {
    padding-bottom: 97px;
    @include max-mq ($ls - 30) {
        padding-bottom: 50px;
    }
    @include min-max-mq( $mb, $sm - 1) {
        max-width: 580px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

    }
    .products-item {
        padding-bottom: 36px;
        padding-top: 40px;
        @include max-mq ($sm - 1) {
            padding-bottom: 0;
        }
        @include max-mq ($mb) {
            padding-top: 25px;
        }
        &:nth-child(even) {
            .product-heading-text-description-wrap {
                order: 2;
                padding-bottom: 38px;
                padding-left: 15px;
                padding-right: 32px;
                @include max-mq ($lg) {
                    padding-left: 42px;
                }
                @include max-mq ($sm - 1) {
                    padding-bottom: 25px;
                    padding-left: 0;
                    padding-right: 15px;
                }
            }
            .product-heading-text-description-inner {
                margin-left: auto;
                margin-right: 0;
            }
            .product-thumbnail-wrap {
                order: 1;
            }
            .product-thumbnail-wrap {
                .counter-number {
                    left: auto;
                    right: -29px;
                    @include max-mq ($sm - 1) {
                        right: 0;
                        top: 0;
                    }
                }
            }
        }
    }
    .product-item-main-link {
        display: flex;
        width: 100%;
        align-items: center;
        @include max-mq ($sm - 1) {
            display: block;
        }
    }
    .product-heading-text-description-inner {
        max-width: 360px;
        @include max-mq ($sm - 1) {
            max-width: 100%;
            width: 100%;
        }
    }
    .product-item-main-title {
        font-size: $font-size-h2 - 2;
        font-weight: $bold;
        letter-spacing: -0.8px;
        margin-bottom: 0.4em;
        text-transform: uppercase;
        @include max-mq ($md) {
            font-size: $font-size-h2 - 5;
        }
        @include max-mq ($sm) {
            font-size: $font-size-h2 - 9;
        }
    }
    .product-item-subtitle {
        font-weight: $semibold;
        letter-spacing: -0.4px;
        margin-top: 0.5em;
        @include max-mq ($md) {
            font-size: $font-size-h4 - 2;
        }
    }
    .product-item-excerpt {
        color: $paragraph_color;
        font-weight: $regular;
        padding-bottom: 15px;
        padding-top: 15px;
        @include max-mq ($mb) {
            padding-top: 0;
        }
        ul {
            margin-top: -4px;
            margin-bottom: 6px;
            @include max-mq ($mb) {
                margin-top: 0;
            }
        }
    }
    .product-heading-text-description-wrap {
        flex: 0 0 40.5%;
        max-width: 40.5%;
        padding-right: 15px;
        padding-bottom: 60px;
        order: 1;
        
        @include max-mq ($md) {
            padding-right: 30px;
        }
        @include max-mq ($md - 92) {
            flex: 0 0 45%;
            max-width: 45%;
        }
        @include max-mq ($sm - 1) {
            flex: 0 0 100%;
            max-width: 100%;
            padding-bottom: 25px;
            padding-right: 15px;
            width: 100%;
        }
    }
    .product-thumbnail-wrap {
        flex: 0 0 59.5%;
        max-width: 59.5%;
        position: relative;
        order: 2;
        @include max-mq ($md - 92) {
            flex: 0 0 55%;
            max-width: 55%;
        }
        @include max-mq ($sm - 1) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
        img {
            display: block;
            width: 100%;
        }
        .counter-number {
            background-color: $green_light_dot;
            color: $main_white;
            font-size: $font-size-h2 - 2;
            font-weight: $light;
            height: 70px;
            line-height: 68px;
            left: -20px;
            position: absolute;
            text-transform: uppercase;
            top: 10px;
            text-align: center;
            width: 70px;
            @include max-mq ($sm - 1) {
                left: 0;
                top: 0;
            }
            @include max-mq ($mb_mb) {
                font-size: $font-size-h2 - 4;
                height: 60px;
                line-height: 58px;
                width: 60px;
            }
        }
    }
    .learn-more {
        padding-top: 24px;
        @include max-mq ($mb) {
            padding-top: 15px;
        }
    }
}
.product-item-subtitle,
.product-item-main-title {
    color: $placeholder_color;
}

