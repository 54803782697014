.page-template-contact-us-page {
    .content-left-calc-position {
        padding-left: 0;
    }
    .cta-content-inner {
        display: block;
        @include max-mq($sm - 1) {
            padding-top: 0;
        }
        .heading-form-inner {
            @extend %center-auto-positioning;
            max-width: 814px;
        }
        .heading-form-wrapper {
            background-color: $main_white;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 55px 0 72px;
            width: 100%;
        }
        .section-map-wrapper {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
        .content-section-heading {
            @extend %margin-auto-positioning;
            text-align: center;
        }
        .section-map-wrapper {
            @include max-mq($sm - 1) {
                margin-left: 0;
            }
        }
    }
}
.contact-information-section-wrapper {
    background-color: $grey_background;
    color: $placeholder_color;
    font-size: $font-size-h2 - 6;
    @include max-mq($lt_l - 20) {
        font-size: $font-size-h2 - 10;
    }
    @include max-mq($mac - 20) {
        font-size: $font-size-h2 - 12;
    }
    @include max-mq($ls) {
        font-size: $font-size-h2 - 13;
    }
    @include max-mq($lg) {
        font-size: $font-size-h2 - 14;
    }
    @include max-mq($lg - 20) {
        font-size: $font-size-h2 - 17;
    }
    @include max-mq($ip - 15) {
        font-size: $font-size-h2 - 18;
    }
    @include max-mq($mb_mb) {
        font-size: $font-size-h2 - 19;
    }
    @include max-mq($xs) {
        font-size: $font-size-h2 - 20;
    }
    a {
        color: $placeholder_color;
        &:hover {
            color: $green_dot;
        }
    }
}
.contact-information-section-inner {
    width: 100%;
}
.contact-information-items-wrap {
    display: flex;
    padding: 56px 0;
    @include max-mq($md) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 410px;
        padding: 17px 22px 17px 0;
        width: 100%;
    }
    @include max-mq($mb_mb) {
        padding-right: 15px
    }
    .contact-information-item {
        position: relative;
        padding-left: 44px;
        @include max-mq($mac - 20) {
            padding-left: 30px;
        }
        @include max-mq($lg) {
            padding-left: 25px;
        }
        @include max-mq($lg - 20) {
            padding-left: 22px;
        }
        @include max-mq($md) {
            padding-bottom: 22px;
            padding-top: 22px;
        }
        @include max-mq($mb_mb) {
            padding-left: 15px
        }
        &:first-child {
            &::before {
                background-color: transparent;
                display: none;
                height: 0;
                width: 0;
            }
        }
        &:before {
            background-color: $main_dark;
            bottom: 0;
            content: '';
            display: block;
            height: 115%;
            left: 0;
            margin: auto;
            position: absolute;
            top: 0;
            width: 2px;
            @include max-mq($md) {
                bottom: auto;
                height: 2px;
                width: 80px;
                left: 22px;
            }
            @include max-mq($mb_mb) {
                left: 15px;
            }
        }
        .icon-wrap {
            color: $main_white;
            display: inline-block;
            height: 59px;
            line-height: 59px;
            margin-right: 20px;
            position: relative;
            text-align: center;
            vertical-align: middle;
            width: 62px;
            @include max-mq($lg) {
                height: 48px;
                line-height: 48px;
                margin-right: 15px;
                width: 50px;
            }
            @include max-mq($mb_mb) {
                height: 43px;
                line-height: 43px;
                margin-right: 10px;
                width: 46px;
            }
            @include max-mq($xs) {
                height: 40px;
                line-height: 40px;
                margin-right: 6px;
                width: 42px;
            }
        }
    }
    .contact-information-number {
        flex: 0 0 22.25%;
        max-width: 22.25%;
        padding-left: 22px;
        @include max-mq($md) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
        @include max-mq($mb_mb) {
            padding-left: 15px;
        }
        .icon-wrap {
            background-color: $contact_blue;
        }
    }
    .contact-information-email {
        flex: 0 0 39.5%;
        max-width: 39.5%;
        @include max-mq($md) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
        .icon-wrap {
            background-color: $general_green;
        }
    }
    .contact-information-address {
        flex: 0 0 38.25%;
        max-width: 38.25%;
        @include max-mq($md) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
        .icon-wrap {
            background-color: $button_orange;
        }
    }
}
.container-contact-information {
    max-width: $container_width + 374;
}