.select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle; 
    min-width: 610px;
    @include max-mq ($md_l) {
        min-width: 410px;
    }
    @include max-mq ($sm - 68) {
        min-width: 360px;
    }
    @include max-mq ($mb_l) {
        min-width: 169px;
        font-size: $font-size-base - 2;
        width: 100% !important;
        max-width: 100%;
    }
    // @include max-mq ($mb_mb) {
    //     min-width: 185px;
    // }
    // @include max-mq ($xs) {
    //     min-width: 169px;
    // }
}
    .select2-container .select2-selection--single {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      height: 61px;
      user-select: none;
      -webkit-user-select: none; 
      @include max-mq ($sm - 68) {
        height: 46px;
      }
      @include max-mq ($xs) {
        height: 42px;
      }
    }
      .select2-container .select2-selection--single .select2-selection__rendered {
        display: block;
        padding-left: 30px;
        padding-right: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
        @include max-mq ($sm - 68) {
            padding-left: 15px;
            padding-right: 35px;
            
        }
        @include max-mq ($xs) {
            padding-left: 8px;
        }
    }
      .select2-container .select2-selection--single .select2-selection__clear {
        position: relative; }
    .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
      padding-right: 8px;
      padding-left: 20px; }
    .select2-container .select2-selection--multiple {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      min-height: 32px;
      user-select: none;
      -webkit-user-select: none; }
      .select2-container .select2-selection--multiple .select2-selection__rendered {
        display: inline-block;
        overflow: hidden;
        padding-left: 8px;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .select2-container .select2-search--inline {
      float: left; }
      .select2-container .select2-search--inline .select2-search__field {
        box-sizing: border-box;
        border: none;
        font-size: 100%;
        margin-top: 5px;
        padding: 0; }
        .select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
          -webkit-appearance: none; }
  
  .select2-dropdown {
    background-color: $main_white;
    border: 1px solid $main_white;
    border-radius: 0;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051; }
  
  .select2-results {
    display: block; }
  
  .select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0; }
  
  .select2-results__option {
    padding: 10px 6px;
    user-select: none;
    -webkit-user-select: none; 
    @include max-mq ($sm - 68) {
        padding: 7px 6px;
    }
}
    .select2-results__option[aria-selected] {
      cursor: pointer; }
  
  .select2-container--open .select2-dropdown {
    left: 0; }
  
  .select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  
  .select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0; 
    box-shadow: 0px 18px 30px 0px rgba($color: #000000, $alpha: 0.15)
}
  
  .select2-search--dropdown {
    display: block;
    padding: 4px; }
    .select2-search--dropdown .select2-search__field {
      padding: 4px;
      width: 100%;
      box-sizing: border-box; }
      .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
        -webkit-appearance: none; }
    .select2-search--dropdown.select2-search--hide {
      display: none; }
  
  .select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
    filter: alpha(opacity=0); }
  
  .select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important; }
  
  .select2-container--default .select2-selection--single {
    background-color: $main_white;
      border: 1px solid $main_white;
      border-radius: 0; 
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #444;
      line-height: 59px; 
      @include max-mq ($sm - 68) {
        line-height: 44px; 
      }
      @include max-mq ($xs) {
        line-height: 40px;
      }
    }
    .select2-container--default .select2-selection--single .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold; }
    .select2-container--default .select2-selection--single .select2-selection__placeholder {
      color: #999; }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
      height: 61px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 49px; 
      @include max-mq ($sm - 68) {
        height: 46px;
        width: 35px; 
      }
      @include max-mq ($xs) {
        height: 42px;
      }

    }
      .select2-container--default .select2-selection--single .select2-selection__arrow b {
        bottom: 0;
        height: 29px;
        left: 50%;
        margin-bottom: auto;
        margin-left: -6px;
        margin-top: auto;
        position: absolute;
        top: 0;
        width: 20px; 
        @include max-mq ($mb) {
            height: 25px; 
        }
        &:after {
            // content: '\f077';
            color: $select_arrows_orange;
            content: '\f078';
            font-family: 'Font Awesome 5 Free';
            font-size: 16px;
            font-weight: 900;
            line-height: 1;
        }
    }
  
  .select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left; }
  
  .select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto; }
  
  .select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default; }
    .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
      display: none; }
  
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    &:after {
        content: '\f077';
        color: $select_arrows_orange;
        font-family: 'Font Awesome 5 Free';
        font-size: 16px;
        font-weight: 900;
        line-height: 1;
    }
}
  
  .select2-container--default .select2-selection--multiple {
    background-color: $main_white;
      border: 1px solid $main_white;
      border-radius: 0;
    cursor: text; }
    .select2-container--default .select2-selection--multiple .select2-selection__rendered {
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 0 5px;
      width: 100%; }
      .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
        list-style: none; }
    .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
      color: #999;
      margin-top: 5px;
      float: left; }
    .select2-container--default .select2-selection--multiple .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-top: 5px;
      margin-right: 10px; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        background-color: $main_white;
        border: 1px solid $main_white;
        border-radius: 0;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
      color: #999;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px; }
      .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
        color: #333; }
  
  .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
    float: right; }
  
  .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto; }
  
  .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto; }
  
  .select2-container--default.select2-container--focus .select2-selection--multiple {
    border: solid black 1px;
    outline: 0; }
  
  .select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default; }
  
  .select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none; }
  
  .select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  
  .select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  
  .select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa; }
  
  .select2-container--default .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield; }
  
  .select2-container--default .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto; }
  
  .select2-container--default .select2-results__option[role=group] {
    padding: 0; }
  
  .select2-container--default .select2-results__option[aria-disabled=true] {
    color: #999; }
  
  .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #ddd; }
  
  .select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em; }
    .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
      padding-left: 0; }
    .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
      margin-left: -1em;
      padding-left: 2em; }
      .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-left: -2em;
        padding-left: 3em; }
        .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
          margin-left: -3em;
          padding-left: 4em; }
          .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
            margin-left: -4em;
            padding-left: 5em; }
            .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
              margin-left: -5em;
              padding-left: 6em; }
  
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $select_arrows_orange;
    color: $main_white; 
}
  
  .select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px; }
  
  .select2-container--classic .select2-selection--single {
    background-color: $main_white;
      border: 1px solid $main_white;
      border-radius: 0;
    outline: 0;
    background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
    background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
    background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }
    .select2-container--classic .select2-selection--single:focus {
      border: 1px solid #5897fb; }
    .select2-container--classic .select2-selection--single .select2-selection__rendered {
      color: #444;
      line-height: 61px; }
    .select2-container--classic .select2-selection--single .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-right: 10px; }
    .select2-container--classic .select2-selection--single .select2-selection__placeholder {
      color: #999; }
    .select2-container--classic .select2-selection--single .select2-selection__arrow {
      background-color: #ddd;
      border: none;
      border-left: 1px solid #aaa;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      height: 61px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
      background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
      background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
      background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); 
      @include max-mq ($sm - 68) {
        height: 46px;
      }
      @include max-mq ($xs) {
        height: 42px;
      }
    }
      .select2-container--classic .select2-selection--single .select2-selection__arrow b {
        border-color: #888 transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0; }
  
  .select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left; }
  
  .select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-right: 1px solid #aaa;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 1px;
    right: auto; }
  
  .select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid #5897fb; }
    .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
      background: transparent;
      border: none; }
      .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
        border-color: transparent transparent #888 transparent;
        border-width: 0 4px 5px 4px; }
  
  .select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
    background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
    background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }
  
  .select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }
  
  .select2-container--classic .select2-selection--multiple {
    background-color: $main_white;
      border: 1px solid $main_white;
      border-radius: 0;
    cursor: text;
    outline: 0; }
    .select2-container--classic .select2-selection--multiple:focus {
      border: 1px solid #5897fb; }
    .select2-container--classic .select2-selection--multiple .select2-selection__rendered {
      list-style: none;
      margin: 0;
      padding: 0 5px; }
    .select2-container--classic .select2-selection--multiple .select2-selection__clear {
      display: none; }
    .select2-container--classic .select2-selection--multiple .select2-selection__choice {
      background-color: $main_white;
      border: 1px solid $main_white;
      border-radius: 0;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px; }
    .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
      color: #888;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px; }
      .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
        color: #555; }
  
  .select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    float: right; }
  
  .select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto; }
  
  .select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto; }
  
  .select2-container--classic.select2-container--open .select2-selection--multiple {
    border: 1px solid #5897fb; }
  
  .select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  
  .select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  
  .select2-container--classic .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    outline: 0; }
  
  .select2-container--classic .select2-search--inline .select2-search__field {
    outline: 0;
    box-shadow: none; }
  
  .select2-container--classic .select2-dropdown {
    background-color: white;
    border: 1px solid transparent; }
  
  .select2-container--classic .select2-dropdown--above {
    border-bottom: none; }
  
  .select2-container--classic .select2-dropdown--below {
    border-top: none; }
  
  .select2-container--classic .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto; }
  
  .select2-container--classic .select2-results__option[role=group] {
    padding: 0; }
  
  .select2-container--classic .select2-results__option[aria-disabled=true] {
    color: grey; }
  
  .select2-container--classic .select2-results__option--highlighted[aria-selected] {
    background-color: #3875d7;
    color: white; }
  
  .select2-container--classic .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px; }
  
  .select2-container--classic.select2-container--open .select2-dropdown {
    border-color: #5897fb; }
  