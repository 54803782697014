.bg-img-position-content-wrapper {
    background-image: url('../images/Rivergreen-center-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 151px 0 137px;
    @include max-mq ($ls - 30) {
        padding: 101px 0 99px;
    }
    @include max-mq ($mb) {
        padding: 80px 0 70px;
    }
    .main-section-heading {
        color: $main_white;
    }
    .about-us-section-content {
        color: $main_white;
        padding-top: 20px;
        max-width: 1040px;
        @extend %center-auto-positioning;
        p {
            margin-bottom: 1.5em;
        }
    }
    a {
        color: $general_green;
        &:hover {
            color: $orange_light_dot;
        }
    }
    .buttons-wrapper {
        padding-bottom: 25px;
        padding-top: 30px;
        text-align: center;
        > div {
            display: inline-block;
            margin: 0 10px;
            @include max-mq ($xs) {
                display: block;
                padding-bottom: 25px;
                &:last-child {
                    padding-bottom: 0;
                }
                a {
                    display: inline-block;
                    min-width: 149px;
                }
            }
        }
    }
}