// Fonts Register
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');

/*Font Variables*/
$primary_font: 'Open Sans', sans-serif;
$secondary_font: 'Open Sans', sans-serif;

//Font Size
$font-size-base: 17px;
$font-size-large: 20px;
$font-size-small: 15px;

//Font Width
$light: 300;
$regular: 400;
$semibold: 600;
$bold: 700;

$large_hero_font: 70px;

$font-size-h1: 46px;
$h1-line: 1.1;
$font-size-h2: 36px;
$h2-line: 1.1;
$font-size-h3: 24px;
$h3-line: 1.1;
$font-size-h4: 20px;
$h4-line: 1.2;
$font-size-h5: 18px;
$h5-line: 1.2;
$font-size-h6: 15px;
$h6-line: 1.2;

$main_background: #fff;

$general_link_color: #2475BC;
$general_link_interact_color: #E35927;
$main_dark: #000;
$main_white: #fff;

// Colors:
$gray: #67696B;
$placeholder_color: #212020;
$menu_color: #BCBCBC;
$paragraph_color: #373737;
$select_arrows_orange: #E35A27;

$button_orange: #E35927;

$contact_blue: #2375BB;

$author_color: #607280;
$grey_background: #F3F3F3;
$grey_form: #EFEFEF;
$footer_background: #303032;
$grey_footer_border: #505050;
$footer_link_color: #9C9C9C;
$footer_paragraph_color: #787878;
$social_bg_color: #444444;
$back_to_top_bg_color: #4B4B4B;

$subheading_blue: #6691CA;
$general_green: #3DB5AF;

$blue_dot: #2475BC;
$purple_dot: #86A2D2;
$green_light_dot: #9FD3CF;
$green_dot: #3DB6B0;
$orange_light_dot: #F09561;
$orange_dot: #E45A27;




// Breakpoints
$fhd: 1920px;
$lt_l: 1660px;
$mac: 1440px;
$lt: 1366px;
$ls: 1280px;
$lg: 1200px;
$ipad: 1030px;
$md: 992px;
$md_l: 900px;
$tab: 800px;
$sm: 768px;
$mb_p: 650px;
$mb_l: 600px;
$mb: 480px;
$ip: 420px;
$mb_mb: 380px;
$xs: 350px;

$container_width: 1192px;
