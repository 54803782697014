.login-header-menu,
.main-navigation {

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;

    li:hover > ul,
    li.focus > ul {
      left: auto;
    }
  }

  li {
    float: left;
    position: relative;
    padding: 0 10px;
    @include max-mq ($mac - 10) {
        padding: 0 7px;
    }
  }
  .current-menu-item {
      > a {
          color: $main_white;
      }
  }
  a {
    color: $menu_color;
    display: block;
    text-decoration: none;
    &:hover {
        color: $main_white;
    }
  }

  ul ul {
    background-color: $main_white;
    float: left;
    left: -999em;
    position: absolute;
    top: auto;
    z-index: 99999;

    li:hover > ul,
    li.focus > ul {
      left: 100%;
    }
  }

  ul ul ul {
    left: -999em;
    top: 0;
  }

  ul ul a {
    width: 200px;
  }
}

/*--------------------------------------------------------------
# Responsive Menu
--------------------------------------------------------------*/
@include max-mq($ls - 30) {
    ul {
        &:after {
            clear: both;
            content: '';
            display: block;
        }
    }
    #header-phone-mobile {
        display: block;
        position: relative;
        padding-left: 42px;
        &:hover {
            background-color: $button_orange;
            color: $main_white;
        }
        .phone-icon-wrap {
            bottom: 0;
            height: 23px;
            left: 17px;
            margin: auto;
            position: absolute;
            top: 0;
            
        }
        a {
            color: $main_dark;
            font-size: $font-size-h3 - 2;
            padding-bottom: 9px;
            padding-top: 9px;
            transition: none;
            &:hover {
                background-color: $button_orange;
                color: $main_white;
            }
            @include max-mq($md) {
                font-size: $font-size-h3 - 3;
            }
            @include max-mq($mb_mb) {
                font-size: $font-size-h3 - 4;
            }
            @include max-mq($xs) {
                font-size: $font-size-h3 - 5;
            }
          }
    }
  #site-navigation {
    position: relative;
    .hamburger {
        &:hover {
            .hamburger-inner {
                &,
                &::before,
                &::after {
                    background-color: $button_orange;
                }
            }
        }
    }
    .hamburger-inner {
        &,
        &::before,
        &::after {
            transition: all .5s;
        }
    }

    > div {
      background-color: $main_white;
      height: 100%;
      padding-top: 50px;
      position: fixed;
      transition: all .5s;
      width: 40%;
      z-index: 999;
      @include max-mq($md) {
        width: 60%;
      }
      @include max-mq($mb) {
        width: 75%;
      }
      .menu-popup-visible,
      .nav-menu {

        .menu-item {
          float: left;
          text-align: left;
          width: 100%;
          padding: 0;
          &.login-icon {
            background-image: url('../images/lock-icon.png');
            background-position: 18px center;
            background-repeat: no-repeat;
            background-color: $main_white;
            padding-left: 40px;
            // transition: all .5s;
            &:hover {
                background-color: $button_orange;
            }
          }
          a {
            color: $main_dark;
            font-size: $font-size-h3;
            padding: 9px 15px;
            transition: none;
            &:hover {
                background-color: $button_orange;
                color: $main_white;
            }
            @include max-mq($md) {
                font-size: $font-size-h3 - 3;
            }
            @include max-mq($mb_mb) {
                font-size: $font-size-h3 - 4;
            }
            @include max-mq($xs) {
                font-size: $font-size-h3 - 5;
            }
          }
        }
        .login-header-menu {
            margin-left: 0;
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            .green-button {
                order: 1;
                a {
                    text-transform: uppercase;
                }
            }
            .login-icon {
                order: 2;
                a {
                    padding-left: 0;
                }
            }
        }
      }
    }

    &.toggled {

      .menu-toggle {

        .fa {

          &:before {
            content: "\f00d";
          }
        }
      }
    }

    // Animations
    &.anim-left {

      > div {
        left: -100%;
        top: 0;
        bottom: 0;
      }

      &.toggled > div {
        left: 0;
      }
    }

    &.anim-right {

      > div {
        bottom: 0;
        right: -100%;
        top: 0;
      }

      &.toggled > div {
        right: 0;
      }

      .menu-toggle {
        position: relative;
        z-index: 9999;
      }
    }

    &.anim-top {

      > div {
        height: auto;
        left: auto;
        padding-top: 0;
        position: absolute;
        right: 0;
        top: 100%;
        opacity: 0;
        visibility: hidden;
        width: 100vw;
      }

      &.toggled > div {
        opacity: 1;
        top: 100%;
        visibility: visible;
      }
    }

    &.anim-popup {

      > div {
        bottom: 0;
        height: 100vh;
        left: 0;
        opacity: 0;
        right: 0;
        top: 0;
        visibility: hidden;
        width: 100%;
      }

      &.toggled > div {
        opacity: 1;
        visibility: visible;
      }

      .menu-toggle {
        position: relative;
        z-index: 9999;
      }
    }

    ul {

      &.nav-menu {

        li {
          margin-left: 0;
          margin-right: 0;

          &.menu-item-has-children {
            transition: all .5s;

            &.expandeds {

              > .arrow-toggle {

                > .fa {

                  &:before {
                    content: "\f106";
                  }
                }
              }
            }
          }

          &.menu-item {

            .arrow-toggle {
              color: $main_dark;
              cursor: pointer;
              display: inline-block;
              font-size: 23px;
              height: 100%;
              margin-right: 0;
              padding: 1px 21px;
              position: absolute;
              z-index: 99;
              right: 0;
              top: 0;
            }
          }
        }
      }

      ul {

        &.sub-menu {
          display: none;
          width: 100%;
          margin-left: 0;
          padding-top: 0;
          top: 0;
          left: 0;
          position: relative;
        }
      }
    }
  }
}

.menu-toggle {
  display: none;
  background-color: transparent;
  border: none;
  color: $main_dark;
  float: right;
  font-size: 28px;
  padding: 3px 2px;

  @include max-mq($ls - 30) {
    display: block !important;
  }
}
.menu-popup-visible {
    @include min-mq ($ls - 29) {
        display: none !important;
    }
}
