.gallery-content-wrapper {
    padding: 68px 0;
    @include max-mq ($mb) {
        padding: 55px 0 45px;
    }
}
.gallery-image-wrap {
    padding-left: 7px;
    padding-top: 7px;
}
.gallery-content-images {
    padding-top: 22px;
}
.gallery-images-slider {
    padding-bottom: 61px;
    .slick-slide {
        padding-right: 47px;
        width: 342px;
        opacity: 0.5;
        @include max-mq ($lt_l) {
            padding-right: 30px;
            width: 330px;
        }
        @include max-mq ($mac) {
            padding-right: 25px;
            width: 300px;
        }
        @include max-mq ($ls) {
            padding-right: 20px;
            width: 280px;
        }
        @include max-mq ($tab) {
            padding-right: 30px;
            width: 310px;
        }
        @include max-mq ($sm - 1) {
            padding-right: 25px;
            width: 300px;
        }
        @include max-mq ($mb_p) {
            padding-right: 20px;
            width: 250px;
        }
        @include max-mq ($mb_l) {
            padding-right: 30px;
            width: 300px;
        }
        @include max-mq ($mb) {
            padding-right: 20px;
            width: 280px;
        }
        @include max-mq ($mb_mb) {
            padding-right: 20px;
            width: 260px;
        }
        @include max-mq ($xs) {
            padding-right: 17px;
            width: 240px;
        }
        &.slick-active {
            opacity: 1;
        }
    }
    .slick-arrow {
        background-color: $general_green;
        bottom: 10px;
        color: $main_white;
        cursor: pointer;
        font-size: $font-size-small;
        height: 25px;
        line-height: 25px;
        text-align: center;
        top: auto;
        transition: all .5s;
        width: 25px;
        &:hover {
            opacity: 0.5;
        }
        &.slick-prev-lf {
            left: auto;
            right: 112px;
        }
        &.slick-next-lf {
            left: auto;
            right: 72px;
        }
    }
}
