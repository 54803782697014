.top-line-colored-wrapp {
    &.bottom-line-colored-wrapp {
        > span {
            height: 7px;    
        }
    }
    > span {
        display: block;
        float: left;
        height: 2px;
        width: 33.333334%;
    }
}
.gradient_blue {
    background-image: -ms-linear-gradient(left, #84A0D0 0%, #2375BA 100%);
    background-image: -moz-linear-gradient(left, #84A0D0 0%, #2375BA 100%);
    background-image: -o-linear-gradient(left, #84A0D0 0%, #2375BA 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #84A0D0), color-stop(100, #2375BA));
    background-image: -webkit-linear-gradient(left, #84A0D0 0%, #2375BA 100%);
    background-image: linear-gradient(to right, #84A0D0 0%, #2375BA 100%);
}
.gradient_orange {
    background-image: -ms-linear-gradient(left, #EE9461 0%, #E25927 100%);
    background-image: -moz-linear-gradient(left, #EE9461 0%, #E25927 100%);
    background-image: -o-linear-gradient(left, #EE9461 0%, #E25927 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #EE9461), color-stop(100, #E25927));
    background-image: -webkit-linear-gradient(left, #EE9461 0%, #E25927 100%);
    background-image: linear-gradient(to right, #EE9461 0%, #E25927 100%);
}
.gradient_green {
    background-image: -ms-linear-gradient(left, #9DD2CE 0%, #3DB4AE 100%);
    background-image: -moz-linear-gradient(left, #9DD2CE 0%, #3DB4AE 100%);
    background-image: -o-linear-gradient(left, #9DD2CE 0%, #3DB4AE 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #9DD2CE), color-stop(100, #3DB4AE));
    background-image: -webkit-linear-gradient(left, #9DD2CE 0%, #3DB4AE 100%);
    background-image: linear-gradient(to right, #9DD2CE 0%, #3DB4AE 100%);
}

#back-to-top {
    background: $back_to_top_bg_color;
    border-radius: 50%;
    border: 1px solid $back_to_top_bg_color;
    bottom: 92px;
    color: $main_white;
    cursor: pointer;
    height: 52px;
    line-height: 48px;
    font-size: $font-size-h3 + 5;
    opacity: 0;
    position: fixed;
    right: 69px;
    text-align: center;
    text-decoration: none;
    transition: all 0.5s;
    width: 52px;
    z-index: 9999;
    @include max-mq ($mac) {
        bottom: 25px;
    }
    @include max-mq ($md_l) {
        bottom: 18px;
        right: 25px;
    }
    &:hover {
        background: $main_white;
        color: $back_to_top_bg_color;
    }
}
#back-to-top.show {
    opacity: 1;
}

::-moz-selection { /* Code for Firefox */
    background: $general_green;
    color: $main_white;
  }
  
  ::selection {
    background: $general_green;
    color: $main_white;
  }