.bg-img-position-content-wrapper {
    &.faq-section-content-wrapper {
        background-attachment: fixed;
        background-image: url('../images/FAQ-section-Rivergreen_about.jpg');
        padding: 101px 0 107px;
        background-size: cover;
        @include max-mq ($ls - 30) {
            padding: 80px 0 79px;
        }
        @include max-mq ($mb) {
            padding: 60px 0 50px;
        }
    }
}
.faq-accordion-wrapper {
    @extend %center-auto-positioning;
    max-width: 915px;
    padding-top: 41px;
    @include max-mq ($ip) {
        padding-left: 0;
        padding-right: 0;
    }
}
.accordion-item {
    background-color: $main_white;
    margin-bottom: 51px;
    &:last-of-type {
        margin-bottom: 15px;
    }
    .accordion-question {
        background-color: $button_orange;
        color: $main_white;
        cursor: pointer;
        font-size: $font-size-base - 1;
        font-weight: $semibold;
        padding: 21px 61px 21px 37px;
        position: relative;
        text-transform: uppercase;
        transition: all .5s;
        @include max-mq ($ipad) {
            padding: 17px 48px 17px 18px;
        }
        @include max-mq($tab + 10) {
            font-size: $font-size-base - 2;
        }
        @include max-mq ($mb) {
            padding: 13px 40px 13px 18px;
        }
        
        &.accordion-item-active {
            background-color: $main_white;
            color: $green_dot;
            .accordion-icon-position {
                i {
                    &:before {
                        content: '\f068';
                    }
                }
            }
        }
        &:hover {
            background-color: $main_white;
            color: $green_dot;
        }
        .accordion-icon-position {
            bottom: 0;
            height: 24px;
            margin: auto; 
            position: absolute;
            right: 43px;
            top: 0;
            @include max-mq ($ipad) {
                right: 29px;
            }
            @include max-mq ($mb) {
                right: 19px;
            }
            i {
                line-height: 15px;
                &:before {
                    line-height: 15px;
                }
            }
        }
    }
    .accordion-answer {
        display: none;
        padding: 0 40px 11px;
        @include max-mq ($ipad) {
            padding: 0 20px 11px;
        }
        p {
            margin-top: 0px
        }
    }
    .accordion-answer-inner {
        max-width: 740px;
    }
}
