.content-left-calc-position {
    @extend %left-calc;
}
.cta-content-wrapper {
    background-color: $grey_background;
}
.cta-content-inner {
    display: flex;
    align-items: center;
    @include max-mq ($sm - 1) {
        display: block;
        padding-top: 75px;
    }
    .heading-form-wrapper {
        flex: 0 0 37%;
        max-width: 37%;
        padding-bottom: 15px;
        padding-right: 25px;
        @include max-mq ($ls) {
            flex: 0 0 43%;
            max-width: 43%;
        }
        @include max-mq ($sm - 1) {
            flex: 0 0 100%;
            max-width: 100%;
            padding-bottom: 75px;
            width: 100%;
        }
    }
    .section-map-wrapper {
        flex: 0 0 63%;
        max-width: 63%;
        @include max-mq ($ls) {
            flex: 0 0 57%;
            max-width: 57%;
        }
        @include max-mq ($sm - 1) {
            flex: 0 0 100%;
            margin-left: -15px;
            max-width: 100%;
            width: 100%;
        }
    }
    .content-section-heading {
        max-width: 250px;
        @include max-mq ($xs) {
            max-width: 230px;
        }
    }
    .main-section-heading {
        font-size: $font-size-h1 - 4;
        letter-spacing: 0px;
        line-height: 1.05;
        margin-bottom: 0.3em;
        text-transform: none;
        @include max-mq ($md) {
            font-size: $font-size-h1 - 7;
        }
        @include max-mq ($mb) {
            font-size: $font-size-h1 - 13;
        }
        @include max-mq ($xs) {
            font-size: $font-size-h1 - 16;
        }
    }
    .cta-content-form-content-wrap {
        padding-top: 11px;
    }
}
.acf-map {
	border: none;
	height: 625px;
	margin: 0;
    width: 100%;
    @include max-mq ($sm - 1) {
        height: 480px;
    }
}

/* fixes potential theme css conflict */
.acf-map img {
   max-width: inherit !important;
}