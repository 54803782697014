.site-footer {
    background-color: $footer_background;
    color: $footer_paragraph_color;
    font-size: $font-size-small;
    font-weight: $regular;
    padding-bottom: 66px;
    padding-top: 56px;
    @include min-max-mq( $sm, $md) {
        font-size: $font-size-small - 2;
    }
    @include max-mq ($mb_mb) {
        font-size: $font-size-small - 2;
    }
    .site-footer-container {
        max-width: 1230px;
    }
    .footer-widgets-wrapper {
        @include max-mq ($ip) {
            display: block;
        }
    }
    .widget-title {
        color: $main_white;
        font-size: $font-size-h3 - 2;
        font-weight: $regular;
        margin-bottom: 2.4em;
        position: relative;
        &:after {
            background-image: url('../images/Rivergreen-footer-colored-line.svg');
            background-position: left center;
            background-repeat: no-repeat;
            bottom: -25px;
            content: '';
            display: block;
            height: 10px;
            left: 0;
            position: absolute;
            width: 60px;
        }
    }
    ul {
        li {
            border-bottom: 1px dashed $grey_footer_border;
            padding-bottom: 16px;
            margin-bottom: 7px;
            &:last-of-type {
                border-bottom: none;
            }
        }
    }
    a {
        color: $footer_link_color;
        &:hover {
            color: $main_white;
        }
    }
    .widget_media_image {
        padding-bottom: 12px;
        max-width: 230px;
        img {
            width: 100%;
        }
    }
    .widget_librafire_social_widget {
        padding-bottom: 25px;
    }
    .social-wrap {
        padding-bottom: 20px;
        li {
            border-bottom: none;
            &:first-of-type {
                a {
                    margin-left: 0;
                }
            }
        }
        a {
            background-color: $social_bg_color;
            border-radius: 50%;
            display: inline-block;
            height: 42px;
            line-height: 42px;
            margin: 0 5px;
            text-align: center;
            vertical-align: middle;
            width: 42px;
            @include max-mq ($mb_mb - 15) {
                margin: 0 2px;
            }
            @include max-mq ($xs) {
                height: 36px;
                line-height: 40px;
                width: 36px;
            }
            &:hover {
                background-color: $main_white;
            }
            i {
                color: $footer_background;
                font-size: $font-size-large - 2;
            }
            
        }
    }
    .footer-column-1 {
        padding-top: 29px;
        flex: 0 0 34.3%;
        max-width: 34.3%;
        @include max-mq ($sm - 1) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include max-mq ($ip) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
        p {
            font-size: $font-size-small + 1;
            letter-spacing: -0.2px;
            @include min-max-mq( $sm, $md) {
                font-size: $font-size-small - 1;
            }
            @include max-mq ($mb_mb) {
                font-size: $font-size-small - 1;
            }
        }
    }
    .footer-column-2 {
        flex: 0 0 22%;
        max-width: 22%;
        @include max-mq ($sm - 1) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include max-mq ($ip) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
    }
    .footer-column-3 {
        flex: 0 0 22%;
        max-width: 22%;
        @include max-mq ($sm - 1) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include max-mq ($ip) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
    }
    .footer-column-4 {
        flex: 0 0 21.7%;
        max-width: 21.7%;
        @include max-mq ($sm - 1) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include max-mq ($ip) {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
        }
    }
}